import * as React from 'react';
import { graphql } from 'gatsby';
import { getSafe } from '../utils/getSafe';
import { Layout } from '../components/Layout';
import Loading from '../components/Loading/Loading';
import Home from '../components/Home/Home';

const Homepage = ({ data }) => {
    if (!data) return null;
    const home = getSafe(() => data.prismicHome.data, {});
    if (!home) return null;

    return (
        <Layout className="home-page">
            <Loading/>
            <Home home={home} />
        </Layout>
    );
};

export const query = graphql`
    {
        prismicHome {
            data {
                body {
                    ... on PrismicHomeDataBodyGarfieldAndFriends {
                        id
                        slice_type
                        primary {
                            more_on_garfield_copy {
                                html
                            }
                            content_copy {
                                html
                            }
                            title {
                                html
                            }
                            right_image {
                                alt
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 1200)
                                    }
                                }
                            }
                            more_on_garfield_title {
                                html
                            }
                        }
                    }
                    ... on PrismicHomeDataBodyGarfieldGang {
                        id
                        slice_type
                        items {
                            character_description {
                                html
                            }
                            character_name {
                                html
                            }
                            character_thumbnail {
                                alt
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 1200)
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicHomeDataBodyPreFooter {
                        id
                        primary {
                            content {
                                html
                            }
                            title {
                                html
                            }
                        }
                        slice_type
                    }
                }
                metadata_description {
                    text
                }
                metadata_title {
                    text
                }
                metadata_keywords {
                    text
                }
                metadata_image {
                    url
                }
            }
        }
    }
`;


export default Homepage;
