import React from 'react';
import Hero from '../components/Hero/Hero';
import GarfieldFriends from '../components/GarfieldFriends/GarfieldFriends';
import GarfieldGang from '../components/GarfieldGang/GarfieldGang';
import PreFooter from '../components/PreFooter/PreFooter';

const checkComponent = (component, index) => {
    switch (component.slice_type) {
        case 'garfield_and_friends':
            return <GarfieldFriends key={index} {...component} />;
        case 'garfield_gang':
            return <GarfieldGang key={index} {...component} />;
        case 'pre_footer':
            return <PreFooter key={index} {...component} />;
        default:
            break;
    }
};

export const getComponent = (components) => {
    if (!components || components.length === 0) return null;
    return components.map((component, index) => {
        return checkComponent(component, index);
    });
};
