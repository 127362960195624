import React from 'react';
import { getSafe } from '../../utils/getSafe';
import DynamicImage from '../DynamicImage/DynamicImage';
import ScrollRevealAnimation from '../ScrollRevealAnimation/ScrollRevealAnimation';
import './PreFooter.scss';

const PreFooter = (props) => {
    const title = getSafe(() => props.primary.title.html, null);
    const content = getSafe(() => props.primary.content.html, null);
    return (
        <div className="pre-footer">
            <div className="container">
                <div className="columns is-multiline is-mobile is-centered">
                    <div className="column is-12-mobile is-12-tablet is-8-desktop">
                        <ScrollRevealAnimation animation="subtleFadeInLeft" delay={0} offset="40px">
                            {title && (
                                <div
                                    className="title "
                                    dangerouslySetInnerHTML={{
                                        __html: title,
                                    }}
                                />
                            )}
                            {content && (
                                <div
                                    className="content"
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                />
                            )}
                        </ScrollRevealAnimation>
                    </div>
                    <div className="column is-11-mobile is-6-tablet is-4-desktop">
                        <ScrollRevealAnimation animation="subtleFadeInRight" delay={0} offset="40px">
                            <DynamicImage
                                imgStyle={{
                                    objectFit: 'contain',
                                    objectPosition: 'left bottom',
                                }}
                                fileName="footer-home.png"
                                className="image"
                                alt="comics"
                            />
                        </ScrollRevealAnimation>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreFooter;
