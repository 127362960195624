import React from 'react';
import PropTypes from 'prop-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { getSafe } from '../../utils/getSafe';
import DynamicImage from '../DynamicImage/DynamicImage';
import ScrollRevealAnimation from '../ScrollRevealAnimation/ScrollRevealAnimation';
import './GarfieldGang.scss';

const GarfieldGang = ({ items }) => {
    const thumbnails = getSafe(() => items, []);
    return (
        <div className="garfield-gang">
            <div className="container">
                <ScrollRevealAnimation animation="subtleFadeInUp" delay={0} offset="50px">
                    <DynamicImage
                        imgStyle={{
                            objectFit: 'contain',
                            objectPosition: 'left bottom',
                        }}
                        fileName="garfield-gang-title.png"
                        className="page-title"
                        alt="Garfield Gang"
                    />
                </ScrollRevealAnimation>
                <div className="characters columns is-multiline is-mobile is-centered">
                    {thumbnails &&
                        thumbnails.map((thumb, index) => {
                            const name = getSafe(() => thumb.character_name.html, null);
                            const description = getSafe(() => thumb.character_description.html, null);
                            const thumbnail = getImage(thumb.character_thumbnail.localFile);

                            return (
                                <div className="character column is-12-mobile is-6-tablet is-4-desktop" key={index}>
                                    <ScrollRevealAnimation
                                        animation="fadeIn"
                                        delay={index * 0.25}
                                        offset="50px"
                                    >
                                        <div className="img-container">
                                            {thumbnail && (
                                                <GatsbyImage
                                                    image={thumbnail}
                                                    alt={getSafe(() => post.image.alt, name)}
                                                    className="thumbnail"
                                                />
                                            )}
                                        </div>
                                        {name && (
                                            <div
                                                className="name"
                                                dangerouslySetInnerHTML={{
                                                    __html: name,
                                                }}
                                            />
                                        )}
                                        {description && (
                                            <div
                                                className="description"
                                                dangerouslySetInnerHTML={{
                                                    __html: description,
                                                }}
                                            />
                                        )}
                                    </ScrollRevealAnimation>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

GarfieldGang.propTypes = {
    items: PropTypes.array,
};

export default GarfieldGang;
