import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '../NavBar/NavBar';
import SEO from '../SEO/SEO';
import Hero from '../Hero/Hero';
import { getComponent } from '../../utils/getComponent';

const Home = ({ home }) => {
  const { metadata_description, metadata_title, metadata_keywords, metadata_image } = home;
  //Getting components of the homepage
  const homeComponents = home.body;
  return (
    <>
      <SEO
        description={metadata_description}
        title={metadata_title}
        keywords={metadata_keywords}
        image={metadata_image}
        type="WebPage"
      />
      <Hero
        leftImage="garfield45/home-garfield-cake.png"
        rightImage="garfield45/home-garfield-45th-mobile.png"
        rightImageMobile="garfield45/home-garfield-45th-mobile.png"
        classNameExternal="hero-videos"
      />

      <NavBar active="home" />
      {/* Homepage components */}
      {getComponent(homeComponents)}
    </>
  );
};

Home.propTypes = {
  home: PropTypes.object,
};

export default Home;
