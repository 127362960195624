import React from 'react';
import PropTypes from 'prop-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { getSafe } from '../../utils/getSafe';
import ScrollRevealAnimation from '../ScrollRevealAnimation/ScrollRevealAnimation';
import './GarfieldFriends.scss';

const GarfieldFriends = ({ primary }) => {
    const title = getSafe(() => primary.title.html, null);
    const copy = getSafe(() => primary.more_on_garfield_copy.html, null);
    const content = getSafe(() => primary.content_copy.html, null);
    const image = getImage(primary.right_image.localFile);
    const moreTitle = getSafe(() => primary.more_on_garfield_title.html, null);
    return (
        <div className="garfield-friends section">
            <div className="container">
                <div className="columns is-multiline is-mobile">
                    <div className="column is-12-mobile is-12-tablet is-7-desktop">
                        <ScrollRevealAnimation animation="subtleFadeInLeft" delay={0} offset="100px">
                            {title && (
                                <div
                                    className="title"
                                    dangerouslySetInnerHTML={{
                                        __html: title,
                                    }}
                                />
                            )}
                            {content && (
                                <div
                                    className="content"
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                />
                            )}
                            {moreTitle && (
                                <div
                                    className="more-title"
                                    dangerouslySetInnerHTML={{
                                        __html: moreTitle,
                                    }}
                                />
                            )}
                            {copy && (
                                <div
                                    className="more-garfield-section"
                                    dangerouslySetInnerHTML={{
                                        __html: copy,
                                    }}
                                />
                            )}
                        </ScrollRevealAnimation>
                    </div>
                    <div className="column is-12-mobile is-8-tablet is-5-desktop">
                        <ScrollRevealAnimation animation="subtleFadeInRight" delay={0} offset="100px">
                            {image && (
                                <GatsbyImage
                                    image={image}
                                    alt={getSafe(() => post.image.alt, 'Garfield')}
                                    className="thumbnail"
                                />
                            )}
                        </ScrollRevealAnimation>
                    </div>
                </div>
            </div>
        </div>
    );
};

GarfieldFriends.propTypes = {
    primary: PropTypes.object,
};

export default GarfieldFriends;
