import React from 'react';
import PropTypes from 'prop-types';
import DynamicImage from '../DynamicImage/DynamicImage';
import ScrollRevealAnimation from '../ScrollRevealAnimation/ScrollRevealAnimation';
import './Hero.scss';

const Hero = ({
    leftImage,
    altLeftImage,
    logo,
    altLogo,
    rightImage,
    altRightImage,
    rightImageMobile,
    altRightImageMobile,
    classNameExternal,
}) => {
    return (
        <div className={`hero-container ${classNameExternal}`}>
            <div className="bg-image" />
            <div className="inner-container">
                {leftImage && (
                    <ScrollRevealAnimation animation="fadeIn" className="left-image" delay={0.15} offset="100px">
                        <DynamicImage
                            imgStyle={{
                                objectFit: 'contain',
                                objectPosition: 'left',
                            }}
                            fileName={leftImage}
                            alt={altLeftImage}
                        />
                    </ScrollRevealAnimation>
                )}
                {logo && (
                    <ScrollRevealAnimation animation="zoomIn" className="logo" delay={0.25} offset="100px">
                        <DynamicImage
                            imgStyle={{
                                objectFit: 'contain',
                                objectPosition: 'left',
                            }}
                            fileName={logo}
                            alt={altLogo}
                        />
                    </ScrollRevealAnimation>
                )}
                {rightImage && (
                    <ScrollRevealAnimation animation="fadeIn" className="right-image" delay={0.35} offset="100px">
                        <DynamicImage
                            imgStyle={{
                                objectFit: 'contain',
                                objectPosition: 'left',
                            }}
                            fileName={rightImage}
                            alt={altRightImage}
                        />
                    </ScrollRevealAnimation>
                )}
                {rightImageMobile && (
                    <DynamicImage
                        imgStyle={{
                            objectFit: 'contain',
                            objectPosition: 'left',
                        }}
                        fileName={rightImageMobile}
                        className="right-image-mobile"
                        alt={altRightImageMobile}
                    />
                )}
            </div>
        </div>
    );
};

Hero.propTypes = {
    leftImage: PropTypes.string,
    altLeftImage: PropTypes.string,
    logo: PropTypes.string,
    altLogo: PropTypes.string,
    rightImage: PropTypes.string,
    altRightImage: PropTypes.string,
    rightImageMobile: PropTypes.string,
    altRightImageMobile: PropTypes.string,
    backgroundImage: PropTypes.string,
    classNameExternal: PropTypes.string,
};

export default Hero;
